import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Seo } from '../components';
import Hero from '../components/hero/hero';
import { TBEvent } from '../utils';
import {
  Blocks
} from '../components';

interface QueryResult {
  strapi: {
    tbEvents: [TBEvent],
  },
};

export const query = graphql`
  query {
    strapi {
      tbEvents(where: { active: true }, limit: 1) {
        id
        title
        location
        date
        registerLabel
        banner {
          url
        }
        structure {
          __typename
          ... on STRAPI_ComponentContentRichText {
            id
            text
          }
          ... on STRAPI_ComponentContentWhiteLogoBar {
            logos {
              link
              logo {
                url
              }
            }
          }
          ... on STRAPI_ComponentContentDropletSectionList {
            title
            sections {
              title
              subTitle
              paragraph
              image {
                formats
              }
              buttonLabel
              buttonLink
            }
          }
          ... on STRAPI_ComponentContentCallToAction {
            title
            subTitle
            buttonLabel
            buttonLink
            buttonType
            color
          }
          ... on STRAPI_ComponentContentTimeline {
            title
            steps {
              title
              description
            }
            conclusion
          }
          ... on STRAPI_ComponentContentVideoEmbed {
            title
            embedCode
            thumbnail {
              url
            }
          }
          ... on STRAPI_ComponentContentTestimonialList {
            title
            testimonials {
              study
              quote
              name
              image {
                alternativeText
                id
                url
              }
              id
              excerpt
              age
            }
          }
        }
      }
    }
  }
`;

const EventPage = ({ data }: { data: QueryResult }) => {
  const page = data.strapi.tbEvents[0];

  return (
    <Layout>
      <Seo title="Event" />
      <Hero
        id={page.id}
        title={page.title}
        location={page.location}
        date={page.date}
        registerLabel={page.registerLabel}
        backgroundImage={page.banner.url}
      />
      <Blocks blocks={page.structure} insetFirst />
    </Layout>
  );
};

export default EventPage;
